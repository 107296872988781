.verified-res-fields-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 40px;
}

.verified-res-fields {
  display: flex;
  gap: 40px;
}

.verified-res-heading {
  width: 100%;
  /* padding: 10px; */
  background-color: rgba(253, 184, 40, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  align-items: center;
}

.verified-res-success-heading-text {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #00bcd4;
}

.verified-res-error-heading-text {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffc107 !important ;
}

.verified-res-img {
  width: 40px;
  height: 40px;
}

.verified-res-content {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
}

.verified-res-field-title {
  width: 30%;
  text-align: left;
  color: #858585;
}

.verified-res-field-value {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-wrap: wrap;
  width: 60%;
  text-align: left;
}

.verified-res-error-message {
  font-family: 'Montserrat', sans-serif;;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #f44336;
}

@media screen and (max-width: 800px) {
  .verified-res-fields-wrap {
    padding: 0px;
  }
}

@media screen and (max-width: 600px) {
  .verified-res-fields {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }

  .verified-res-field-title {
    width: 100%;
  }

  .verified-res-field-value {
    width: 100%;
  }
}
